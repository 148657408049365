import Component from '../../libs/component';
import { register } from '../../libs/register';

class FlipCard extends Component {

    constructor(container) {
        super('elem-flip-card');

        this.container = container;
        this.flipCardContainer = this.container.querySelector(`${this._el('container', true)}`);
        this.flipCards = this.flipCardContainer.querySelectorAll(`${this._el('content', true)}`);
        this.flipCardButtons = this.flipCardContainer.querySelectorAll(`${this._el('button', true)}`);

        this.flipCardButtons.forEach((el) => {
            el.addEventListener('click', () => {
                this._flipY();
            });
        });
    }

    _flipY() {
        this.flipCardContainer.classList.toggle('flip-y');
    }
}

register.registerClass('.elem-flip-card', FlipCard);