import Component from '../../libs/component';
import { register } from '../../libs/register';


export class LottieAnimation extends Component {

    constructor(container) {
        super('elem-lottie-animation');

        this.container = container;
        this.lottieAnimations = this.container.querySelectorAll('.lottie');

        this.mobileClass = `${this._el('mobile',true)}`;
        this.tabletClass = `${this._el('tablet',true)}`;
        this.desktopClass = `${this._el('desktop',true)}`;

        this.lottieAnimationMobile = this.container.querySelector(this.mobileClass);
        this.lottieAnimationTablet = this.container.querySelector(this.tabletClass);
        this.lottieAnimationDesktop = this.container.querySelector(this.desktopClass);

        this.activeAnimation;
        this.lottieAnimationPlayer;

        this._init();
    }

    async _init() {
        this.lottieAnimationPlayer = await import('lottie-web/build/player/lottie_light');

        document.addEventListener('bpMobile', () => {
            this._setActiveAnimation(this.lottieAnimationMobile, this.mobileClass);
        });
        document.addEventListener('bpTablet', () => {
            this._setActiveAnimation(this.lottieAnimationTablet, this.tabletClass);
        });
        document.addEventListener('bpDesktop', () => {
            this._setActiveAnimation(this.lottieAnimationDesktop);
        });

        this._setDeviceActiveAnimation();

        document.addEventListener('scroll', () => {
            this._checkActiveAnimationPlayState();
        });
    }

    _setDeviceActiveAnimation() {
        if (window.deviceBreakpoints.bpMobile.matches === true) {
            this._setActiveAnimation(this.lottieAnimationMobile, this.mobileClass);
            return;
        }

        if (window.deviceBreakpoints.bpTablet.matches === true) {
            this._setActiveAnimation(this.lottieAnimationTablet, this.tabletClass);
            return;
        }

        this._setActiveAnimation(this.lottieAnimationDesktop);
        return;
    }

    _setActiveAnimation(animation, fallbackClass = null) {
        if (!animation) {
            // Fallback to Desktop Animation
            animation = this.lottieAnimationDesktop;
            if (!animation) {
                return;
            }

            if (fallbackClass) {
                animation.classList.add(fallbackClass.substring(1));
            }
        }

        if (this.activeAnimation === animation) {
            return;
        }

        if (this.activeAnimation) {
            this.lottieAnimationPlayer.stop(this._getLottieAnimationName(this.activeAnimation));
        }

        this.activeAnimation = animation;
        this._checkActiveAnimationPlayState();
    }

    _playActiveAnimation() {
        this.lottieAnimationPlayer.play(this._getLottieAnimationName(this.activeAnimation));
    }

    _getLottieAnimationName(lottieAnimation) {
        if (lottieAnimation) {
            return lottieAnimation.getAttribute('data-name');
        }

        return null;
    }

    _checkActiveAnimationPlayState() {
        if (!this.activeAnimation) {
            return;
        }

        if (this._isInViewPort(this.activeAnimation)) {
            this._playActiveAnimation();
        }
    }

    _isInViewPort(element) {
        return element.getBoundingClientRect().top < window.innerHeight;
    }
}

register.registerClass('.elem-lottie-animation', LottieAnimation);