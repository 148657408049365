//REGISTER
import { register } from '../libs/register';

////COMPONENTS
// Base structure
import './base-page';
import './base-header';
import './base-header-lite';
import './base-sticky-menu';

// // Layout
import './layout-masonry';
import './layout-prelogin';

// // Elements
import './elem-custom-select';
import './elem-custom-select-filter-table';
import './elem-quick-links';
import './elem-cta-modal';
import './elem-card';
import './elem-accordion';
import './elem-accordion-faq';
import './elem-searchbar';
import './elem-iframe';
import './elem-icon-anim';
import './elem-flip-card';
import './elem-lottie-animation';

// // Media
import './media-picture';
import './media-video';

// //Widget
import './widget-modal';
import './widget-modal-generic';
import './widget-modal-comparison';
import './widget-modal-form';
import './widget-form-steps';
import './widget-accordion-select';
import './widget-tab';
import './widget-table';
import './widget-share';
import './widget-slideshow';
import './widget-products-slider';
import './widget-round-images';
import './widget-logos-grid';
import './widget-chatbot';
import './widget-api-documentation';
import './widget-commission-overlay';

register.apply(document);
