import Component from '../../libs/component';
import { register } from '../../libs/register';
import { analytics } from '../../libs/analytics';
import { setBlockScroll, removeBlockScroll } from '../base-page';


class GenericModal extends Component {
    constructor(container) {
        super('widget-modal-generic');

        this.modal = container;
        this.modalId = container.id;
        this.modalBody = this.modal.querySelector(this._el('body',true));
        this.backdrop = container.querySelector(this._el('backdrop',true));
        this.btnClose = container.querySelector(this._el('close',true));

        this._addCustomScrollBar = this.addCustomScrollBar.bind(this);
        this._removeCustomScrollBar = this.removeCustomScrollBar.bind(this);

        this._modalInit();
    }

    // Init Modal
    _modalInit() {
        this.modal
            .querySelectorAll(`${this._el('cta',true)}, ${this._el('close',true)}, .elem-cta`)
            .forEach((el) => {
                el.addEventListener('click', (ev) => {
                    this.sendAnalytics('click', ev.target.textContent.trim() || ev.target.getAttribute('aria-label'));
                });
            });


        this.btnClose
            .addEventListener('click', (ev) => {
                ev.preventDefault();
                this._closeModal();
            });

        document.removeEventListener('bpMobileTablet', this._removeCustomScrollBar);
        document.addEventListener('bpMobileTablet', this._removeCustomScrollBar);
        document.removeEventListener('bpDesktop', this._addCustomScrollBar);
        document.addEventListener('bpDesktop', this._addCustomScrollBar);
        document.removeEventListener('orientLandscape', this._closeModal);
        document.addEventListener('orientLandscape', this._closeModal);
    }

    _openModal(tabTarget = null) {
        setBlockScroll();
        this.modalBody.scrollTo(0, 0);
        this.modal.classList.add("show");
        this.modal.classList.remove('hide');
        this._addClickBackdrop();

        if (tabTarget) {
            const tabContainer = this.modal.querySelector('.widget-tab');

            if (tabContainer && tabContainer.objReference) {
                tabContainer.objReference._openTabByIdFromModal(tabTarget);
            }
        }

        if (window.deviceBreakpoints.bpDesktop.matches === true) {
            this.addCustomScrollBar();
        }

        this.sendAnalytics('impression');
    }


    _openModalOnLoad() {
        setBlockScroll();
        this.modal.classList.add("show");

        if (window.deviceBreakpoints.bpDesktop.matches === true) {
            this.addCustomScrollBar();
        }

        this.sendAnalytics('impression');
    }

    _closeModal() {
        this.modal.classList.remove('show');
        this.modal.classList.add('hide');

        removeBlockScroll();
        this.removeCustomScrollBar();
    }

    _addClickBackdrop() {
        this.backdrop.addEventListener('click', this._clickBackdrop);
    }

    _removeClickBackdrop() {
        this.backdrop.removeEventListener('click', this._clickBackdrop);
    }

    _clickBackdrop({target}) {
        console.log(target);
        target
            .parentElement
            .objReference
            ._closeModal();
    }

    async addCustomScrollBar() {
        const modalBody = this.modal.querySelector(this._el('body',true));
        const PerfectScrollbar = await import('perfect-scrollbar');
        this.scrollbar = new PerfectScrollbar.default(modalBody, {
            suppressScrollX: true,
            wheelPropagation: false,
            swipeEasing: true
        });

        this.scrollbar.update();
    }

    removeCustomScrollBar() {

        if (this.scrollbar) {
            this.scrollbar.destroy();
            this.scrollbar = null;
        }
    }

    sendAnalytics(action,cta=null) {

        let title = this.modal.querySelector(this._el('title',true)) || this.modal.querySelector(this._el('header-title',true)),
            content = this.modal.querySelector(this._el('step.active',true)) || this.modal.querySelector(this._el('body',true)),
            ctaList = null;

        //if (!cta)    // Creating an array with cta labels
        ctaList = [...this.modal.querySelectorAll(`${this._el('cta',true)}, .elem-cta`)]
                        .filter((el) => {
                            if (el.offsetParent !== null)
                                return true;    // CTA is visible
                            return false;
                        })
                        .map((el) => el.textContent.trim());

        analytics.sendData({
            'event': 'popup',
            'language': document.documentElement.lang,
            'type': null,
            'popup': {
                'action': action,
                'elements': {
                    'title': title ? title.textContent.trim() : null,
                    'content': content ? content.textContent.trim() : null,
                    'name': null,
                    'cta': {
                        'existing': ctaList || null,
                        'clicked': cta
                    }
                }
            }
        });
    }
}

register.registerClass('.widget-modal-generic', GenericModal);