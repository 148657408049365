import Component from '../../libs/component';
import { register } from '../../libs/register';
import { setBlockScroll, removeBlockScroll } from '../base-page';
import { isDevMode, isWCMModeDisabled } from '../../libs/utils';

class CommissionOverlay extends Component {

    constructor(container) {
        super('overlay');
        
        this.container = container;
        this.content = container.querySelector('.overlay__wrapper');
        this.dataOverlayParam = this.container.id;

        const buttonClose = container.querySelector('.j-closeOverlay');
        buttonClose.addEventListener('click', () => {
            this._closeCommissionOverlay();
        });

        // Add outside click closing
        this.container.addEventListener('click', (ev) => {
            if (this.content && !this.content.contains(ev.target)) {
                this._closeCommissionOverlay();
            }
        });

        this.tabContent = this.container.querySelector('.widget-tab__tab-content');
        this.observer = new MutationObserver((mutationList) => {
            let previousMutations = [];
            for(let mutation of mutationList) {
                if(mutation.attributeName == 'class' && mutation.target.classList.contains('active')) {
                    if (previousMutations.includes(mutation.target)) {
                        return;
                    }
                    previousMutations.push(mutation.target);
                    this._trackAnalytics(mutation.target);
                }
            }
        });

        this.loadedCommissions = false;
        this.commissionEndpoint = this.content.dataset.commissionEndpoint;
        this.commissionWrappers = this.container.querySelectorAll('.overlay__commissionWrapper');

        this._addCustomScrollBar = this.addCustomScrollBar.bind(this);
        this._removeCustomScrollBar = this.removeCustomScrollBar.bind(this);

        document.removeEventListener('bpMobileTablet', this._removeCustomScrollBar);
        document.addEventListener('bpMobileTablet', this._removeCustomScrollBar);
        document.removeEventListener('bpDesktop', this._addCustomScrollBar);
        document.addEventListener('bpDesktop', this._addCustomScrollBar);

        if (window.deviceBreakpoints.bpDesktop.matches) {
            this.addCustomScrollBar();
        }
    }

    _openModal() {
        this._openCommissionOverlay();
        this._onOpen();
        this._startActiveTabObserver()
    }

    _openCommissionOverlay() {
        this.container.classList.add('overlay--open')
        setBlockScroll();

        let commissionOverlay = this.container;

        if (this.commissionWrappers.length > 1) {
            commissionOverlay = this.commissionWrappers[0];
        }

        this._trackAnalytics(commissionOverlay);
    }

    _closeCommissionOverlay() {
        this.container.classList.remove('overlay--open', 'send--success', 'send--error');
        removeBlockScroll();
        this._stopActiveTabObserver();
    }

    _onOpen() {
        // load commission for each table
        if(this.commissionEndpoint && !this.loadedCommissions) {
            if (!this.commissionWrappers || !this.commissionWrappers.length) {
                return;
            }

            for(const wrapper of this.commissionWrappers){
                this._loadCommissions(wrapper);
            }
            this.loadedCommissions = true;
        }
    }

    _startActiveTabObserver() {
        if (!this.tabContent) {
            return;
        }

        this.observer.observe(this.tabContent, { subtree: true, attributes: true });
    }

    _stopActiveTabObserver() {
        this.observer.disconnect();
    }

    _loadCommissions(wrapper) {
        if (!wrapper) {
            return;
        }

        const _self = this;
        const acquiringId = wrapper.dataset.commissionAcquiringId;
        if(!acquiringId) {
            _self._replaceSelectors(wrapper, {}); // replace with empty directly
            return;
        }

        if (!isDevMode() && !isWCMModeDisabled()) {
            try {
                fetch(this.commissionEndpoint + '?acquiringId=' + acquiringId)
                    .then((res) => res.json())
                    .then((data) => {
                        _self._replaceSelectors(wrapper, data);
                        wrapper.classList.add('loaded');
                        console.log('Loaded commissions from remote');
                    });
            } catch (error) {
                _self._replaceSelectors(wrapper, {});
                wrapper.classList.add('loaded');
                console.log('Failed get acquiring data', error);
            }
        }
        else {
            setTimeout(() => {
                this._replaceSelectors(wrapper, {
                    acquiringId: 1,
                    consumerPrice: 'n.a.',
                    commercialPrice: '1,99%',
                    pagobancomatPrice: '0,69%',
                    jcbUpiPrice: '2,29%',
                    eeaConsumerPrice: '0,65%',
                    eeaCommercialPrice: '0,30%',
                    eeaPagobancomatPrice: 'n.a.',
                    eeaJcbUpiPrice: 'n.a.',
                    transactionPrice: ''
                });
                wrapper.classList.add('loaded');
                console.log('Loaded commissions - locally');
            }, 500);
        }
    }

    _replaceSelectors(wrapper, data) {
        const items = Array.from(wrapper.querySelectorAll('.overlay__item,.overlay__disclaimer,.overlay__bannerTtx,.overlay__commission-txt,.overlay__commission-description'));
        for(const item of items) {
            item.innerHTML = item.innerHTML.replace(/\$([a-zA-Z0-9]+)(?:\|([a-zA-Z0-9.,%]+))?/g, (match, p1, p2) => {
                if(!p2) return data[p1] || '';
                if(!data[p1]) return p2;
                const p1Value = parseFloat(data[p1].replace(',', '.'));
                const p2Value = parseFloat(p2.replace(',', '.'));
                return p2Value > p1Value ? data[p1] : p2;
            });
        }
    }

    async addCustomScrollBar() {
        const PerfectScrollbar = await import('perfect-scrollbar');
        this.scrollbar = new PerfectScrollbar.default(this.content, {
            suppressScrollX: true,
            wheelPropagation: false,
            swipeEasing: true
        });

        this.scrollbar.update();
    }

    removeCustomScrollBar() {
        if (this.scrollbar) {
            this.scrollbar.destroy();
            this.scrollbar = null;
        }
    }

    _trackAnalytics(commissionOverlay) {
        let overlayData = this._getAnalyticsData(commissionOverlay);
        let commissionOverlayId = this.dataOverlayParam;

        if (commissionOverlayId == 'overlay--amex') {
            overlayData.overlayTitle = 'amex: commissioni e condizioni';
        }
        if (commissionOverlayId == 'overlay--plugin') {
            overlayData.overlayTitle = 'Integra Xpay';
        }
        this._trackPopup(overlayData);
    }

    _getAnalyticsData(commissionOverlay) {
        const textRegex = /\s\s+/g;
        let overlayTitle = commissionOverlay.querySelectorAll('.overlay__title');
        overlayTitle = overlayTitle.length > 0 ? overlayTitle[0].textContent.replace(textRegex, '') : '';

        if (!overlayTitle) {
            overlayTitle = commissionOverlay.querySelectorAll('.overlay__subtitle');
            overlayTitle = overlayTitle.length > 0 ? overlayTitle[0].textContent.replace(textRegex, '') : '';

            if (!overlayTitle) {
                overlayTitle = commissionOverlay.querySelectorAll('.overlay__parSubtitle');
                overlayTitle = overlayTitle.length > 0 ? overlayTitle[0].textContent.replace(textRegex, '') : '';
            }
        }

        let overlayText = commissionOverlay.querySelector('.overlay__par,.overlay__txt');
        if (overlayText) {
            overlayText = overlayText.textContent.replace(textRegex, '');
        }

        let commissionOverlayData = {
            overlayTitle: overlayTitle,
            overlayText: overlayText
        }

        return commissionOverlayData;
    }

    _trackPopup(commissionOverlayData) {
        let data = {
            event: 'popup',
            popup: {
                action: 'impression',
                elements: {
                    title: commissionOverlayData.overlayTitle,
                    content: commissionOverlayData.overlayText,
                    name: null
                }
            }
        };
        window.dataLayer.push(data);
    };
}

register.registerClass('.overlay', CommissionOverlay);