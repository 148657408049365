import { getURLParams } from './utils';


///////////////////////////////////////////////////
//////////// TRIGGER WHEN VISIBLE
//////////// Send event when a single element becomes visibile on viewport
///////////////////////////////////////////////////
export const triggerWhenVisible = (element,callback,singleUse=false,visThreshold=100) => {

    const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting === true) {
            if (singleUse) observer.disconnect();
            callback(entries,observer);
        }
    }, { threshold: [ visThreshold/100 ] });
    
    observer.observe(element);
}

///////////////////////////////////////////////////
//////////// TRIGGER WHEN NOT VISIBLE
//////////// Send event when a single element becomes not visibile anymore on viewport
///////////////////////////////////////////////////
export const triggerWhenNotVisible = (element,callback,singleUse=false,visThreshold=100) => {

    const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting === false) {
            if (singleUse) observer.disconnect();
            callback(entries,observer);
        }
    }, { threshold: [ visThreshold/100 ] });
    
    observer.observe(element);
}



///////////////////////////////////////////////////
//////////// GET REDIRECT URL PARAMS
//////////// Retrieve query string parameters for Redirect template
///////////////////////////////////////////////////
export const getRedirectQueryUrlParams = () => {

    const params = getURLParams();
    const payload = {
        abi: '',
        abibancapartner: '',
        affinity: '',
        cpp: '',
        circuito: '',
        nomeprodottonexi: '',
        idiniziativa: '',
        idunivocoofferta: '',
        procedure_id: '',
        offer_id: '',
        rd: ''
    };
    let counter = 0;

    console.log('url params:',params);

    for (let p in params) {
        if (!params[p]) continue;

        if (payload[p] != undefined) {
            payload[p] = decodeURIComponent( params[p] );
            counter++;
        }
    }

    console.log('payload:',payload);

    return counter > 0 ? payload : null;
}

///////////////////////////////////////////////////
//////////// INJECT REDIRECT ANALYTICS MANDATORY DATA
//////////// Inject mandatory data for Redirect template into analytics data
///////////////////////////////////////////////////
export const injectMandatoryData = (originalData,mandatoryData) => {

    const newData = {
        language: document.documentElement.lang,
        procedure_id: mandatoryData.procedure_id || null,
        card: {
            scheme: mandatoryData.circuito || null,
            abi: mandatoryData.abi || null,
            affinity: mandatoryData.affinity || null,
            cpp: mandatoryData.cpp || null,
        },
        campaign_id: mandatoryData.idiniziativa || null,
        offer_id: mandatoryData.offer_id || null
    };

    return {
        ...originalData,
        ...newData
    };
}



///////////////////////////////////////////////////
//////////// SHARING UTILS
///////////////////////////////////////////////////
export const shareTwitter = (data) => {

    let twitterData = '',
        twitterUrl;

    if (data.text) {
        twitterData = data.title + ' / ' + data.text + "&url=" + data.url;
    } else {
        twitterData = data.title + "&url=" + data.url;
    }

    twitterUrl = "https://twitter.com/intent/tweet?text=" + twitterData;

    window.open(twitterUrl,'share-twitter','width=650,height=500');
}

export const shareFacebook = (data) => {

    const appID = '382518490051185';
    const facebookUrl = `https://www.facebook.com/dialog/share?app_id=${appID}&href=${data.url}&display=popup`;

    window.open(facebookUrl,'share-facebook','width=650,height=500');
}

export const shareLinkedin = (data) => {

    const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${data.url}`;

    window.open(linkedinUrl,'share-linkedin');
}

export const shareXing = (data) => {

    const xingUrl = `https://www.xing.com/social/share/spi?url=${data.url}`;

    window.open(xingUrl,'share-xing');
}

export const shareEmail = (data) => {

    let emailData = '',
        emailUrl;

    if (data.text) {
        emailData = data.text + '%0D%0A%0D%0A';
    }

    // '%20' for cc and bbc is uqual as whitespace
    emailData += "&subject=" + data.title + "&body=" + data.url;
    emailUrl = "mailto:?cc=%20&bcc=%20" + emailData;

    window.open(emailUrl,'share-email');
}

export const copyToClipboard = async (data) => {

    const clipboardText =  await import("clipboard-polyfill/text");
    let clipboardApi = navigator.clipboard || clipboardText;

    try {
        clipboardApi
            .writeText( decodeURIComponent(data.url) )
            .then(() => {
                console.log('Card info successfully copied to clipboard!')
                return true;
            })
            .catch(() => {
                console.error('Card info NOT copied to clipboard!')
            });
            
    } catch(err) {
        console.log('Clipboard write not available!');
    }

    return false;
}
