import Component from '../../libs/component';
import { register } from '../../libs/register';
import { analytics } from '../../libs/analytics';
//import { triggerWhenVisible } from '../../libs/commons';


class Tabs extends Component {

    constructor(container) {
        super('widget-tab');

        this.tabContainer = container;
        this.tabGroup = this.tabContainer.querySelector('.widget-tab__tab-group');
        this.dropdown = this.tabContainer.querySelector('.widget-tab__dropdown');
        this.dropdownSelect = this.dropdown.querySelector('.widget-tab__dropdown-select');
        this.tabItems = this.tabGroup.querySelectorAll('.widget-tab__btn');
        this.tabContent = this.tabContainer.querySelector('.widget-tab__tab-content');
        this.tabPane = this.tabContent.querySelectorAll('.widget-tab__tab-pane');

        this.tabId = window.location.href.split('tabId=').pop().split('&')[0];
        this.tabButtonPaneSelected = document.getElementById(this.tabId);
        this.tabButtonSelected = document.querySelector('[data-target="#' + this.tabId + '"]');

        this.transformTabInDropdown = this.tabContainer.getAttribute('data-transform-tab-in-dropdown') === 'true';

        this._setTab();

        this._bindEventListeners();

        // Set initial state based on viewport
        if (window.deviceBreakpoints.bpMobileTablet.matches === true) {
            this._addMobileBehavior();
        } else {
            this._removeMobileBehavior();
        }

    }

    _bindEventListeners() {
        this.tabItems.forEach((el) => {
            el.addEventListener('click', (ev) => {
            this._openTab(ev);
            });
        });
        if (this.dropdownSelect) {
            this.dropdownSelect.addEventListener('change', (ev) => this._openDropdownTab(ev));
        }
        document.addEventListener('bpMobileTablet', () => this._addMobileBehavior() );
        document.addEventListener('bpDesktop', () => {
            this._removeMobileBehavior();
            this._destroySwiper();
        });

        window.addEventListener('load', () => this._scrollToTab() );
        window.addEventListener('visibilitychange', () => this._scrollToTab() );
    }


    _addMobileBehavior() {
        if (this.tabContainer.querySelector('.swiper-wrapper').scrollWidth > window.innerWidth) {
            this._initSwiper();
        }
        if (this.transformTabInDropdown) {
            this.tabGroup.classList.add('hidden-on-mobile');
            this.dropdown.classList.add('visible-on-mobile');
        }

    }

    _removeMobileBehavior() {
        this._destroySwiper();
        this.tabGroup.classList.remove('hidden-on-mobile');
        this.dropdown.classList.remove('visible-on-mobile');
    }


    _openDropdownTab(ev) {
        const _tabTarget = ev.target.value;
        this._removeActiveTabs();
        document.querySelector(_tabTarget).classList.add('active');
        this.tabItems.forEach((btn) => {
            if (btn.dataset.target === _tabTarget) {
                btn.classList.add('active');
            }
        });
    }

    _scrollToTab() {
        if (this.tabButtonSelected) {
            this.tabGroup.scrollIntoView();
        }
    }


    _removeActiveTabs() {
        this.tabPane.forEach((el) => {
            el.classList.remove('active');
        });

        this.tabItems.forEach((el) => {
            el.classList.remove('active');
        });
    }

    _setTab() {
        if (this.tabItems) {
            this._removeActiveTabs();
            if (this.tabButtonSelected) {
                this.tabButtonPaneSelected.classList.add('active');
                this.tabButtonSelected.classList.add('active');
            }
            else {
                this.tabPane[0].classList.add('active');
                this.tabItems[0].classList.add('active');
            }
        }
    }

    _openTab(el){

        let _button = el.currentTarget;
        let _tabTarget = _button.dataset.target;

        this._removeActiveTabs();

        document.querySelector(_tabTarget).classList.add("active");

        _button.classList.add("active");

        // console.debug(_button,_tabTarget);
        /*if (this.swiper) {

            let _parent = _button.parentNode;
            let slide = this.swiper.el;
            let activeIndex = this.swiper.activeIndex;

            // this.swiper.slideTo(_parent);
            // this.swiper.update();

            console.debug(_button,_parent, slide,activeIndex);
        }*/
    }


    _openTabByIdFromModal(tabId) {

        this._removeActiveTabs();

        const tabPane = this.tabContainer.querySelector(`#${tabId}`);
        const tabButton = this.tabContainer.querySelector(`[data-target="#${tabId}"]`);


        if (tabPane && tabButton) {
            tabPane.classList.add('active');
            tabButton.classList.add('active');


            if (this.dropdownSelect) {
                this.dropdownSelect.value = tabButton.dataset.target;

                const selectedOption = this.dropdown.querySelector(`.custom-select__option[data-value="${tabButton.dataset.target}"]`);

                if (selectedOption) {
                    selectedOption.classList.add('custom-select__option--selected');
                }

                this.dropdown.querySelectorAll('.custom-select__option').forEach((option) => {
                    if (option !== selectedOption) {
                        option.classList.remove('custom-select__option--selected');
                    }
                });

                const opener = this.dropdown.querySelector('.custom-select__opener');
                if (opener && selectedOption) {
                    opener.textContent = selectedOption.textContent;
                }
            }
        }
    }



    async _initSwiper() {
        const Swiper = await import('swiper');
        if (!this.swiper) {
            this.swiper = new Swiper.default(this.tabGroup, {
                slidesPerView: '2',
                grabCursor: true,
                observer: true,
                observeParents: true,
                observeSlideChildren: true,
                watchOverflow: true,
                scrollbar: {
                    el: ".swiper-scrollbar",
                },
                /*on: {
                    slideChangeTransitionEnd: (swiper) => {
                        const activeSlide = this.tabContainer.querySelector('.swiper-slide-active');
                        const btn = activeSlide.querySelector(`${this._el('btn',true)}`);
                        this._sendAnalytics(
                            'impression',
                            btn.textContent.trim(),
                            btn.dataset.target || '',
                            swiper.realIndex + 1,
                        );
                    }
                }*/
            });

        } else {
            this.swiper.update();
        }

        /*triggerWhenVisible(this.tabContainer, () => {
            const slides = this.tabContainer.querySelectorAll(`${this._el('tab-item',true)}`);

            if (slides) {
                const btn = slides[0].querySelector(`${this._el('btn',true)}`);
                this._sendAnalytics(
                    'impression',
                    btn.textContent.trim(),
                    btn.dataset.target || '',
                    1,
                );
            }
        }, true);*/
    }

    _destroySwiper() {

        if (this.swiper) {
            this.swiper.destroy();
            this.swiper = undefined;
        }
    }

    _sendAnalytics(action,label,url,position) {

        analytics.sendData({
            'event': 'slider',
            'action': action,           // "impression" or "click"
            'label': label,             // Product name
            'click_url': url || '',     // Primary CTA href
            'position': position        // Swiper slide position
        });
    }
}

register.registerClass('.widget-tab', Tabs);
